<template>
  <div class="flex w-full bg-img vx-row no-gutter items-center justify-center mt-10" id="page-login">
    <div class="vx-col">
      <div class="vx-row no-gutter justify-center items-center">
        <div class="vx-col lg:block">
          <center>
            <img src="@/assets/logo.png" alt="logo" class="logo mx-auto lg:w-1/4 mb-10" />
          </center>
        </div>

        <div class="vx-col mx-auto lg:w-3/4">
          <div class="p-8 login-tabs-container">
            <div class="mb-4">
              <button
                class="oeno_portfolio_title_small bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l float-right"
                @click="logout()">Logout</button>
              <h1 class="text-4xl oeno_portfolio_title">{{ getUserName }}</h1>
              <h3 class="text-4xl oeno_portfolio_title_small">
                {{ getUserReference }}<br /><br />
              </h3>
              <hr class="oeno_divider" />
              <h1 class="text-4xl"><i>My Account</i><br /><br /></h1>
              <p class="oeno_portfolio_text">
                The prices shown are estimates and could change when you come to
                sell your wines.<br />
                Your broker : {{ getUserBroker }}
              </p>
            </div>

            <span id="download_loader" v-if="!$store.state.portfolio"></span>

            <div v-if="$store.state.portfolio">
              <div v-for="total in getTotals" v-bind:key="total.currency" class="grid grid-cols-5 gap-4 pb-2">
                <div><span class="oeno_portfolio_total_currency">{{ total.currency }} {{ total.buytotal.toFixed(2) }}</span></div>
                <div class="col-span-3"></div>
                <div class="" v-if="total.total && !total.total_max">
                  <span class="oeno_portfolio_total_total float-right">{{ currencyFormatter(total.total)
                    }}</span><br clear="all" />
                  <span :class="[
                    (total.changePrice >= 0)
                      ? 'oeno_portfolio_price_up  float-right'
                      : 'oeno_portfolio_price_down  float-right',
                  ]">{{ getChangeTotal(total) }}</span>
                </div>
                <div class="" v-if="total.total_max">
                  <span class="oeno_portfolio_total_total float-right">{{ currencyFormatter(total.total_max)
                    }}</span><br clear="all" />
                  <span :class="[
                    (total.changePriceMax >= 0)
                      ? 'oeno_portfolio_price_up  float-right'
                      : 'oeno_portfolio_price_down  float-right',
                  ]">{{ getChangeTotalMax(total) }} Max</span>
                </div>
                <div><span class="oeno_portfolio_total_currency"></span></div>

                <div class="col-span-3"></div>

                <div class="" v-if="total.total_min">
                  <span class="oeno_portfolio_total_total float-right">{{ currencyFormatter(total.total_min)
                    }}</span><br clear="all" />
                  <span :class="[
                    (total.changePriceMin >= 0)
                      ? 'oeno_portfolio_price_up  float-right'
                      : 'oeno_portfolio_price_down  float-right',
                  ]">{{ getChangeTotalMin(total) }} Min</span>
                </div>

              </div>
              <!-- <div class="mt-10 mb-10">
                <h1 @click="showAnalytics()" class="text-3xl oeno_portfolio_title font-bold float-right cursor-pointer">Analytics</h1>
                <h1 class="text-5xl"><i>Portfolio</i></h1>
              

              </div> -->
              <div v-for="investment in $store.state.portfolio" v-bind:key="investment.id">
                <div v-for="item in investment.items" v-bind:key="item.id" class=""
                  @click="showDetail(item.wine_id, investment.id)">
                  <div v-if="item.quantity2 >= 0" class="grid grid-cols-5 gap-4 cursor-pointer" key="">
                    <div>


                      <img :src="wineImage(item)" class="oeno_portfolio_wineimage"
                        onerror="this.style.display='none'" />
                    </div>
                    <div class="col-span-3">
                      <span class="oeno_portfolio_winetitle">{{
                        item.wines.name
                        }}</span><br />
                      <span class="oeno_portfolio_winesubtitle">{{
                        winary(item)
                        }}</span><br />
                      <span class="oeno_portfolio_winequantity">{{
                        showquantity(item)
                        }}</span>

                    </div>
                    <div v-if="item.quantity2 > 0">

                      <div v-if="item.livePrices.data['price-average'] && !item.livePrices.data['price-max']">
                        <span class="float-right oeno_portfolio_price">{{ currencyFormatter(getPrice(investment, item))
                          }}&nbsp;{{ getCurrency(investment, item) }} </span>
                        <br /><span :class="[
                          priceUp(investment, item)
                            ? 'float-right oeno_portfolio_price_up'
                            : 'float-right oeno_portfolio_price_down',
                        ]">{{ getChange(investment, item) }}</span>
                      </div>
                      <div v-if=" item.livePrices.data['price-max']">

                      <span class="float-right oeno_portfolio_price">{{ currencyFormatter(getPriceMax(investment, item))
                        }}&nbsp;{{ getCurrency(investment, item) }} Max</span>
                      <br /><span :class="[
                        priceUpMax(investment, item)
                          ? 'float-right oeno_portfolio_price_up'
                          : 'float-right oeno_portfolio_price_down',
                      ]">{{ getChangeMax(investment, item) }}</span>
                      <span class="float-right oeno_portfolio_price">{{ currencyFormatter(getPriceMin(investment, item))
                        }}&nbsp;{{ getCurrency(investment, item) }} Min</span>
                      <br /><span :class="[
                        priceUpMin(investment, item)
                          ? 'float-right oeno_portfolio_price_up'
                          : 'float-right oeno_portfolio_price_down',
                      ]">{{ getChangeMin(investment, item) }}</span>
                    </div>
                    </div>
                    <div class=" oeno_portfolio_price" style="text-align: right;" v-else>

                      In Brokerage
                    </div>
                    <div class="col-span-5">
                      <hr class="oeno_divider_sm" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/axios.js";
// import moment from "moment";

export default {
  data() {
    return {
    };
  },
  mounted() {
    this.$nextTick(() => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.token,
      };

      HTTP.get("/api/get-portfolio-full-v2", { headers: headers }).then(
        (response) => {
          if (response.data.statuscode == 200) {
            this.$store.dispatch('storePortfolioData', { portfoliodata: response.data.data.investment });
          }
        }
      );
    })
  },
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
    getUserBroker() {
      var broker = "";
      if (this.$store.state.user) {
        broker =
          this.$store.state.user.brokerName +
          " " +
          this.$store.state.user.brokerEmail;
      }
      return broker;
    },
    getTotals() {
      const values = [];
      // let topWine = null;
      let maxPerformance = -Infinity;
      this.$store.state.portfolio.forEach((investment) => {
        investment.items.forEach((item) => {
          const itemPrice = this.getValuesForItem(investment, item);

          const performance = parseFloat(itemPrice.changePrice);

          if (performance > maxPerformance) {
            maxPerformance = performance;
            this.topWine = {
              wine: item.wines,
              currency: itemPrice.currency,
              changePrice: parseFloat(itemPrice.changePriceMax),
              total: parseFloat(itemPrice.total_max),
              buytotal: parseFloat(itemPrice.buytotal),
              investment_tax_amount: parseFloat(investment.tax_amount) // Including the tax amount for clarity
            };
          }
          let pos = values.findIndex(
            (value) => value.currency === itemPrice.currency
          );

          if (pos > -1) {

            values[pos].total += parseFloat(itemPrice.total);
            values[pos].total_min += parseFloat(itemPrice.total_min);
            values[pos].total_max += parseFloat(itemPrice.total_max);

            values[pos].buytotal += parseFloat(itemPrice.buytotal);

            // // Add tax amount to the last item
            // if (key === investment.items.length - 1) {
            //   values[pos].buytotal += parseFloat(investment.tax_amount);
            // }

            values[pos].changePrice += parseFloat(itemPrice.changePrice);
            values[pos].changePriceMin += parseFloat(itemPrice.changePriceMin);
            values[pos].changePriceMax += parseFloat(itemPrice.changePriceMax);

          } else {
            values.push({
              currency: itemPrice.currency,

              changePrice: parseFloat(itemPrice.changePrice),
              changePriceMin: parseFloat(itemPrice.changePriceMin),
              changePriceMax: parseFloat(itemPrice.changePriceMax),

              total: parseFloat(itemPrice.total),
              total_min: parseFloat(itemPrice.total_min),
              total_max: parseFloat(itemPrice.total_max),

              buytotal:
                parseFloat(itemPrice.buytotal),
            });
          }

        });
      });

      return values;
    },

    // 
  },
  methods: {

    logout() {
      this.$store.dispatch('login', { token: '' })
      this.$router.push('/login')
      this.$store.dispatch('storePortfolioData', {});
    },
    showDetail(id, orderId) {
      this.$router.push({ name: "page-portfolio-detail", params: { id: id, orderId: orderId } });
    },
    showAnalytics() {
      this.$router.push("/analytics");
    },
    winary(wine) {
      var winary = "n/a";
      var vintage = "";
      var size = "";

      if (wine.wines) {
        if (wine.wines.winery) {
          vintage = wine.wines.vintage;
          if (wine.wines.winery.name) {
            winary = wine.wines.winery.name;
          }
          if (wine.wines.size.name) {
            size = wine.wines.size.name;
          }
        }
      }

      winary = winary + " " + vintage;

      if (size != "") {
        winary = winary + " " + size + "ML";
      }

      return winary;
    },
    showquantity(wine) {
      var quantity = 0;
      var unitsPerCase = 0;
      var retStr = "";

      if (wine.quantity) {
        quantity = parseInt(wine.quantity2);
      }
      if (wine.wines) {
        if (wine.wines.units_per_case) {
          unitsPerCase = parseInt(wine.wines.units_per_case);
        }
      }

      if (quantity > 0) {
        if (quantity > 1) {
          retStr = quantity + " Cases";
        } else {
          retStr = quantity + " Case";
        }
      }

      if (unitsPerCase > 0) {
        if (unitsPerCase > 1) {
          retStr = retStr + " (" + unitsPerCase + " bottles per case)";
        } else {
          retStr = retStr + " (" + unitsPerCase + " bottle per case)";
        }
      }

      return retStr;
    },

    wineImage(wine) {
      var wineimageurl = process.env.VUE_APP_WINE_DUMMY_IMAGE;
      // console.log(wine)
      if (wine.wines) {


        if (wine.wines.images) {

          if (wine.wines.images[0]) {
            wineimageurl =
              process.env.VUE_APP_WINE_IMAGE_PATH + wine.wines.images[0].name;
          }
        }
      }

      return wineimageurl;
    },
    //     getValuesForItem(investment, wine) {


    //  console.log(wine.livePrices.data["price-average"],"jfeoiujifuheigujuirgt")
    //       var buytotal =
    //         wine.total_unsold_brokerage * wine.bottle_price
    //         -
    //         wine.totalMoneyReinvested;
    //       //totalPurchaseMoneyReinvested
    //       var buytotalCurrentAvailBrok =
    //         wine.total_unsold_brokerage * wine.bottle_price;

    //       var total =
    //         parseInt(
    //           parseFloat(
    //             wine.total_unsold_brokerage *
    //             wine.livePrices.data["price-average"]
    //           ) * 100
    //         ) / 100;
    //       var total_min =
    //         parseInt(
    //           parseFloat(
    //             wine.total_unsold_brokerage * wine.livePrices.data["price-min"]
    //           ) * 100
    //         ) / 100;
    //       var total_max =
    //         parseInt(
    //           parseFloat(
    //             wine.total_unsold_brokerage * wine.livePrices.data["price-max"]
    //           ) * 100
    //         ) / 100;
    //       // console.log(wine.livePrices)
    //       if (wine.livePrices.data["price-min"] == 0) {
    //         total_min = buytotalCurrentAvailBrok;
    //       }

    //       // console.log(wine.livePrices)
    //       if (wine.livePrices.data["price-max"] == 0) {
    //         total_max = buytotalCurrentAvailBrok;
    //       }


    //       if (wine.livePrices.data["price-average"] == 0) {
    //         total = buytotalCurrentAvailBrok;
    //       }

    //       // if (isInLastSixMonths == "Y") {
    //       //   if (total < buytotalCurrentAvailBrok) {
    //       //     total = buytotalCurrentAvailBrok;
    //       //   }
    //       // }

    //       var changePerc =
    //         ((parseFloat(total) - parseFloat(buytotalCurrentAvailBrok)) /
    //           parseFloat(buytotalCurrentAvailBrok)) *
    //         100;

    //       var changePrice =
    //         parseFloat(total) - parseFloat(buytotalCurrentAvailBrok);
    //       var changePriceMin =
    //         parseFloat(total_min) - parseFloat(buytotalCurrentAvailBrok);
    //       var changePriceMax =
    //         parseFloat(total_max) - parseFloat(buytotalCurrentAvailBrok);

    //       return {

    //         changePerc: changePerc,
    //         buytotalCurrentAvailBrok: buytotalCurrentAvailBrok,
    //         changePrice: changePrice,
    //         changePriceMin: changePriceMin,
    //         changePriceMax: changePriceMax,
    //         total_max: total_max,
    //         total_min: total_min,
    //         total: total,
    //         buytotal: buytotal,
    //         currency: investment.currency,
    //       };
    //     },





    getValuesForItem(investment, wine) {


      var buytotal =
        wine.quantity2 *
        wine.wines.units_per_case * wine.bottle_price;

      var total =
        (wine.total_bottles - wine.total_unsold_brokerage) *
        wine.livePrices.data["price-average"];

      var total_min =
        (wine.total_bottles - wine.total_unsold_brokerage) *
        wine.livePrices.data["price-min"];

      var total_max =
        (wine.total_bottles - wine.total_unsold_brokerage) *
        wine.livePrices.data["price-max"]

        ;

      // console.log(total, wine.livePrices.data["price-average"], wine.wines.units_per_case, wine.bottle_price, wine.quantity2, wine.quantity, wine.wines.name, wine)
      if (wine.livePrices.data["price-average"] == 0) {
        total = buytotal;
      }



      var changePerc =
        ((parseFloat(total) - parseFloat(buytotal)) / parseFloat(buytotal)) * 100;

      var changePercMin =
        ((parseFloat(total_min) - parseFloat(buytotal)) / parseFloat(buytotal)) * 100;

      var changePercMax =
        ((parseFloat(total_max) - parseFloat(buytotal)) / parseFloat(buytotal)) * 100;
      var changePrice = parseFloat(total) - parseFloat(buytotal);
      //       var changePrice =
      //         parseFloat(total) - parseFloat(buytotalCurrentAvailBrok);
      var changePriceMin =
        parseFloat(total_min) - parseFloat(buytotal);
      var changePriceMax =
        parseFloat(total_max) - parseFloat(buytotal);
      return {
        changePerc: changePerc,
        changePercMin: changePercMin,

        changePercMax: changePercMax,
        buytotal: buytotal,
        changePrice: changePrice,
        changePriceMin: changePriceMin,
        changePriceMax: changePriceMax,
        total: total,
        total_max: total_max,
        total_min: total_min,
        currency: investment.currency,
      };
    },

    getPrice(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.total;
    },
    getPriceMin(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.total_min;
    },
    getPriceMax(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.total_max;
    },

    getCurrency(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.currency;
    },

    getChange(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return this.currencyFormatter(priceValues.changePrice) + "(" + this.currencyFormatter(priceValues.changePerc) + "%)";
    },

    getChangeMin(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return this.currencyFormatter(priceValues.changePriceMin) + "(" + this.currencyFormatter(priceValues.changePercMin) + "%)";
    },

    getChangeMax(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return this.currencyFormatter(priceValues.changePriceMax) + "(" + this.currencyFormatter(priceValues.changePercMax) + "%)";
    },

    getChangeTotal(total) {


      var changePerc = (total.changePrice / (total.total - total.changePrice)) * 100;

      return this.currencyFormatter(total.changePrice) + "(" + changePerc.toFixed(2) + "%)";
    },
    getChangeTotalMin(total) {


      var changePerc = (total.changePriceMin / (total.total_min - total.changePriceMin)) * 100;

      return this.currencyFormatter(total.changePriceMin) + "(" + changePerc.toFixed(2) + "%)";
    },
    getChangeTotalMax(total) {


      var changePerc = (total.changePriceMax / (total.total_max - total.changePriceMax)) * 100;

      return this.currencyFormatter(total.changePriceMax) + "(" + changePerc.toFixed(2) + "%)";
    },

    priceUp(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePrice >= 0) {
        return true;
      } else {
        return false;
      }
    },

    priceUpMin(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePriceMin >= 0) {
        return true;
      } else {
        return false;
      }
    },

    priceUpMax(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePriceMax >= 0) {
        return true;
      } else {
        return false;
      }
    },

    currencyFormatter(value) {
      const options = {
        significantDigits: 2,
        thousandsSeparator: ",",
        decimalSeparator: ".",
        symbol: "",
      };

      if (typeof value !== "number") value = parseFloat(value);
      value = value.toFixed(options.significantDigits);

      const [currency, decimal] = value.split(".");
      return `${options.symbol} ${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator
      )}${options.decimalSeparator}${decimal}`;
    },
  },
};
</script>

<style>
#download_loader {
  width: 100px;
  height: 100px;
  background-image: url(/dotloader.svg);
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  margin: auto;
  background-size: 100%;
}
</style>
